import * as React from "react";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";

import ImageComponent from "../components/ImageComponent";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Link} from "gatsby";

const IndexPage = () => {

    return (
        <DefaultLayoutComponent activeCategory="Home">
            <GatsbySeo
                title='La Compta de Papa'
                description='Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.'
                canonical='https://lacomptadepapa.fr/'
                openGraph={{
                    url: 'https://lacomptadepapa.fr/',
                    title: 'La Compta de Papa',
                    description: 'Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.',
                }}
            />
            <main className={"pt-5 mt-lg-4 mt-5"}>
                <div className="slider-area  position-relative">
                    <div className="slider-active">
                        <div className="single-slider">
                            <div className="slider-cap-wrapper slider-height d-sm-flex align-items-end align-items-sm-center">
                                <div className="hero-caption">
                                    <span data-animation="fadeInUp" data-delay=".2s">L'expertise à votre service</span>
                                    <h1 data-animation="fadeInUp" data-delay=".4s">La compta de papa</h1>
                                    <p data-animation="fadeInUp" data-delay=".6s">Un cabinet d’expertise comptable indépendant pour vous écouter et vous accompagner dans tous vos projets entrepreneuriaux.</p>
                                </div>
                                <div className="hero-img position-relative">
                                    {/*<img src="assets/img/hero/h1_hero1.jpg" alt="" data-animation="fadeInRight"
                                         data-transition-duration="5s"/>*/}
                                    <ImageComponent imageName={'teamwork.jpg'} alt={'Bannière de la compta de papa'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-shape d-none d-xl-block">
                        <ImageComponent imageName={"hero-shape.png"} alt=""/>
                    </div>
                </div>
                <section className="categories-area section-padding">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 col-md-12">
                                <div className="section-tittle text-center mb-50">
                                    <h2>Tout ce dont vous avez besoin pour gérer votre entreprise</h2>
                                    <p>De la mise à disposition d'outils numériques à l'analyse du marché en passant par l'accompagnement fiscal et comptable.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-30">
                                    <h5><Link to="/missions">RH et paie</Link></h5>
                                    <div className="cat-icon w-25 m-auto py-2">
                                        <ImageComponent imageName={"human-resources.png"} alt={'Icone ressources humaines'}/>
                                        {/*<img src="assets/img/icon/services1.svg" alt="" />*/}
                                    </div>
                                    <div className="cat-cap">
                                        <p>Fini la perte de temps récurrente liée à la compléxité du droit français.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat single-cat2 text-center mb-30">
                                    <h5><Link to="/missions">Comptabilité et fiscalité</Link></h5>
                                    <div className="cat-icon w-25 m-auto py-2">
                                        <ImageComponent imageName={"compta.png"} alt={'Icone comptabilité et fiscalité'}/>
                                        {/*<img src="assets/img/icon/services2.svg" alt="" />*/}
                                    </div>
                                    <div className="cat-cap">
                                        <p>Pas de contrainte, plus de temps et de la sérénité !</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat single-cat3 text-center mb-30">
                                    <h5><Link to="/missions">Offre numérique : MEG</Link></h5>
                                    <div className="cat-icon w-25 m-auto py-2">
                                        <ImageComponent imageName={"computer.png"} alt={'Icone offre numérique'}/>
                                        {/*<img src="assets/img/icon/services3.svg" alt="" />*/}
                                    </div>
                                    <div className="cat-cap">
                                        <p>Nous proposons une solution collaborative, sécurisée et facile d’utilisation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-area1 bottom-padding">
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-xl-5 col-lg-5 col-md-8 d-none d-sm-inline-flex">
                                <div className="about-img about-img1  ">
                                    <ImageComponent imageName={"proximity.jpg"} alt="Descriptif rapide du cabinet" />
                                </div>
                            </div>
                            <div className="offset-xl-1 col-xxl-6 col-xl-6 col-lg-7 col-md-9">
                                <div className="about-caption about-caption1">
                                    <div className="section-tittle mb-30">
                                        <h2>Notre champ d'expertise</h2>
                                    </div>
                                    <div className="single-about">
                                        <h5>Comptabilité et fiscalité</h5>
                                        <p>Nous gérons votre comptabilité et prenons en charge vos obligations légales : vos comptes annuels, vos déclarations fiscales, la révision et pointage de vos comptes, l'attestation de vos comptes, la saisie de vos pièces comptables.</p>
                                        {/*<p>Combine sections from Olla's vast component library and create beautiful.</p>*/}
                                    </div>
                                    <div className="single-about">
                                        <h5>Ressources Humaines et paies</h5>
                                        <p>Le droit du travail français est complexe et des évolutions sont apportées régulièrement. Cela peut donc devenir pour les chefs d’entreprises une charge lourde et récurrente nécessitant beaucoup de temps.</p>
                                    </div>
                                    <div className="single-about">
                                        <h5>Création d'entreprise</h5>
                                        <p>La motivation première de notre cabinet est la satisfaction du client. Pour cela nous prenons à cœur de vous écouter, de comprendre au mieux votre activité et vos besoins afin de vous proposer un accompagnement personnalisé.</p>
                                    </div>
                                    <div className="single-about b-0">
                                        <h5>Et encore bien plus !</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="brand-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 col-md-10">
                                <div className="section-tittle section-tittle2 text-center d-flex justify-content-around">
                                    <h2 className={'m-0'}>N'hésitez pas, vous pouvez <Link to={"/nous-contacter"} className={'text-decoration-underline text-warning'}>nous contacter</Link> !</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial top-padding bottom-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <ImageComponent imageName={"about1.png"} alt={"Nous analysons votre activité pour vous aider du mieux possible"}/>
                            </div>
                            <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-9">
                                <div className="testimonial-area">
                                    <div className="section-tittle mb-50">
                                        <h2>Notre motivation première</h2>
                                    </div>
                                    <div className="h1-testimonial-active">
                                        <div className="single-testimonial">
                                            <div className="testimonial-caption ">
                                                <div className="testimonial-top-cap">
                                                    <p>La motivation première de notre cabinet est la satisfaction du client. Pour cela nous prenons à cœur de vous écouter, de comprendre au mieux votre activité et vos besoins afin de vous proposer un accompagnement personnalisé.</p>
                                                </div>
                                                <div className="testimonial-founder d-flex align-items-center">
                                                    <div className="founder-text">
                                                        <span>Damien Commarmond</span>
                                                        <p>La Compta de Papa</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="wantToWork-area w-padding2">
                    <div className="container border-bottoms pb-50">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-8 col-lg-9 col-md-8">
                                <div className="wants-wrapper">
                                    <div className="wantToWork-caption wantToWork-caption2">
                                        <h2>Des envies de création ?</h2>
                                        <p>Nous vous proposons de vous aider et de vous accompagner de l’étincelle de votre projet jusqu’à son aboutissement.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 d-flex d-sm-block justify-content-center ">
                                <Link to="/nous-contacter" className="btn_1 wantToWork-btn brand-btn f-right">Nous contacter</Link>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </DefaultLayoutComponent>
    )
};

export default IndexPage;
